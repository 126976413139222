import React, { useEffect, useState } from "react";

const About = () => {

  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);

  const [content, setContent] = useState('');

  useEffect(() => {
    fetch('/Privacy_policy.html')
      .then(response => response.text())
      .then(data => setContent(data));
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
}


export default About;
