import React, { useEffect, useState } from "react";
import "react-modal-video/scss/modal-video.scss";
import Tilt from "react-tilt";
import Subscribe from "./components/Subscribe";
import Footer from "./components/Footer";

const HomeThree = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home3/home3-slider.png)`,
  });

  const color = localStorage.getItem("color");
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [slideCapDesc, setSlideCapDesc] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
      if (window.innerWidth <= 768) {
        setSlideCapDesc('Introducing Magic Friends: <br>Where Technology Becomes Child\'s Play');
      } else {
        setSlideCapDesc('Introducing Magic Friends: Where Technology<br><span> Becomes Child\'s Play </span>');
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Call the function initially to set the state based on the initial screen size
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (color === "color-1") {
      if (screenSize < 768 ) {
        setBgImg({
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home3/BG_MOBILE.png)`,
        });
      } else {
        setBgImg({
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home3/home3-slider.png)`,
        });
      }
    } else if (color === "color-2") {
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/Homev3.png)`,
      });
    } else {
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/Homev3.png)`,
      });
    }
  }, [color, screenSize]);

  document.body.classList.remove("landing-page");
  document.body.classList.remove("home-style-two");
  document.body.classList.add("home-style");
  document.body.classList.add("three");

  return (
    <div>
      <section id="home" className="home home-three vertical-scrolling" style={bgImg}>
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <div className="home-contain">
                <img
                  id="logoFresh"
                  className="logo-container"
                  src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                  alt="logo"
                />
                <div className="text-white">
                  <div className="contain">
                    <h1 className="text-white">
                      <div>The First</div>
                      <div>
                        <span className="f-bold text-orange">AI Assistant</span>
                      </div>
                      <div>
                        <span className="f-bold f-color text-white">for Children</span>
                      </div>
                    </h1>
                    <p className="slide-cap-desc" dangerouslySetInnerHTML={{ __html: slideCapDesc }}></p>
                  </div>
                  <div className="play-button">
                    <Subscribe />
                  </div>
                  <h1 id="middleH">A Virtual Companion for Learning & Fun</h1>
                </div>
              
              </div>
            </div>
            <div className="col-sm-7">
              <div className="home-right">
                <Tilt options={{ perspective: 110, speed: 400, max: 1.2, scale: 1 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/home3/home-3-img.png`}
                    className="img-fluid"
                    alt="slider-caption"
                  />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
        
      </section>
  
      <Footer />
    </div>
  );
};

export default HomeThree;