import React, { useEffect } from "react";

const Subscribe = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="footer-text">
            <form
              action="https://magicfriends.us11.list-manage.com/subscribe/post?u=a528ded91edaab03839f76a51&amp;id=1cb415b441"
              className="footer-form needs-validation"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              target="_blank"
            >
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="enter your email"
                  name="EMAIL"
                  id="mce-EMAIL"
                  required
                />
              </div>
              <div className="form-button">
                <button
                  type="submit"
                  className="btn btn-custom theme-color"
                  id="mc-submit"
                >
                 <span>SIGN UP FOR BETA</span>
                </button>
              </div>
              {/* Hidden field for Mailchimp bot detection, do not remove */}
              <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                <input type="text" name="b_a528ded91edaab03839f76a51_1cb415b441" tabIndex="-1" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
